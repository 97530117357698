import * as React from "react"
import { useLocation } from "@reach/router"
import { graphql } from "gatsby"

import { TagManagerContext } from "../core/contexts/tagManager"
import Layout from "../components/layout/layout"
import ArticlePreviewCard from "../components/articles/articlePreviewCard"
import Seo from "../components/seo/seo"
import Divider from "../components/divider/divider"
import Card from "../components/card/card"
import SubscribeForm from "../components/subscribeForm/subscribeForm"
import HeroGrid from "../components/grid/heroGrid"
import TextButton from "../components/buttons/textButton"
import ElevatedButton from "../components/buttons/elevatedButton"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { TypeBlogArticle } from "../app/createPages"

const heroItems = [
  {
    title: "Solutions",
    description:
      "See how we are helping influential brands win digital customers.",
    btnLabel: "View Solutions",
    btnHref: "/solutions",
  },
  {
    title: "Services",
    description: "See how we are creating unique customer experiences.",
    btnLabel: "View Services",
    btnHref: "/services",
  },
  {
    title: "Resources",
    description:
      "See how we are informing small businesses on digital transformation.",
    btnLabel: "View Resources",
    btnHref: "/resources",
  },
]

type TypeData = {
  site: {
    siteMetadata: {
      description: string
    }
  }
  allSanityPost: {
    edges: {
      node: TypeBlogArticle
    }[]
  }
}

type IndexPageProps = {
  data: TypeData
}

const IndexPage = ({ data }: IndexPageProps) => {
  const { description } = data.site.siteMetadata
  const location = useLocation()
  const tagManager = React.useContext(TagManagerContext)

  const onLinkClick = (item: { label: string; href: string }) => {
    tagManager?.onLinkClickEvent({
      link_type: "navigation",
      link_url: location.origin + item.href,
      link_name: item.label,
      link_location: "page",
    })
  }

  return (
    <Layout>
      <Seo title="Home" description={description} />
      <section className="container mt-5 pb-5 mb-5">
        <div className="mw-800">
          <h4>Transformation starts in the Cloud</h4>
          <p className="subtitle1 mb-5">{description}</p>
        </div>
        <HeroGrid items={heroItems} />
      </section>
      <div className="container">
        <Divider />
      </div>
      <section className="container pt-5 pb-5 mt-5 mb-5">
        <div className="row justify-space-between align-flex-end">
          <div className="col-xs-12 col-md-8 pr-2">
            <h1 className="caption">Solution</h1>
            <h6>Generate More Digital Revenue at a Reduced Cost.</h6>
            <p className="subtitle1">
              Unlock an accelerated approach to optimizing digital revenue. With
              digitally-transformed sales and marketing function areas, reduce
              costs while increasing customer reach and experiences.
            </p>
            <div
              onClick={() =>
                onLinkClick({
                  label: "How Can I Optimize My Sales and Marketing?",
                  href: "/solutions/optimizing-your-sales-and-marketing",
                })
              }
            >
              <TextButton
                label="How Can I Optimize My Sales and Marketing?"
                icon={faArrowRight}
                href="/solutions/optimizing-your-sales-and-marketing"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="container pt-5 pb-5">
        <div className="row justify-space-between align-flex-end">
          <div className="col-xs-12 col-md-8 pr-2">
            <h1 className="caption">Service</h1>
            <h6>Provide User&apos;s with a Quality Product Experience.</h6>
            <p className="subtitle1">
              For most businesses, digital properties, such as a website, are
              their most valuable marketing assets. Digital properties allow for
              unique and agile customer experiences that always meet the
              customer&apos;s needs.
            </p>
            <div
              onClick={() =>
                onLinkClick({
                  label: "How Can I Provide a Quality Experience?",
                  href: "/services/product-development",
                })
              }
            >
              <TextButton
                label="How Can I Provide a Quality Experience?"
                icon={faArrowRight}
                href="/services/product-development"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="container pt-5 pb-5">
        <div className="row justify-space-between align-flex-end">
          <div className="col-xs-12 col-md-8 pr-2">
            <h1 className="caption">Solution</h1>
            <h6>Build a Loyal Digital Community and Customer Base.</h6>
            <p className="subtitle1">
              Create collections of content which inform and entertain, but also
              persuade and ultimately drive the behaviours that align with
              business outcomes. A well-planned content ecosystem can change
              everything.
            </p>
            <div
              onClick={() =>
                onLinkClick({
                  label: "How Can I Create a Content Ecosystem?",
                  href: "/solutions/creating-a-content-ecosystem",
                })
              }
            >
              <TextButton
                label="How Can I Create a Content Ecosystem?"
                icon={faArrowRight}
                href="/solutions/creating-a-content-ecosystem"
              />
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <Divider />
      </div>
      <section className="container pt-5 pb-5">
        <Card isPrimary={true} canHover={false}>
          <div className="pt-2 pb-2">
            <div className="row justify-space-between align-center">
              <h6 className="mb-3">How can we help you?</h6>
              <span className="text-black">
                <div
                  onClick={() =>
                    onLinkClick({
                      label: "Get in touch with us",
                      href: "/contact-us",
                    })
                  }
                >
                  <ElevatedButton
                    label="Get in touch with us"
                    href="/contact-us"
                  />
                </div>
              </span>
            </div>
          </div>
        </Card>
      </section>
      <div className="container">
        <Divider />
      </div>
      <section className="container pt-5 pb-5 mt-5 mb-5">
        <div className="row justify-space-between">
          <div className="col-xs-12 col-md-6 mb-3">
            <h1 className="caption">Resource</h1>
            <h6>New at iPhupha Digital Blog.</h6>
            <p className="subtitle1">
              All the latest insights, strategies, and digital developments.
            </p>
            <div
              onClick={() =>
                onLinkClick({
                  label: "Read more on our blog",
                  href: "/resources/blog",
                })
              }
            >
              <TextButton
                label="Read more on our blog"
                icon={faArrowRight}
                href="/resources/blog"
              />
            </div>
          </div>
          <div className="col-xs-12 col-md-5">
            <ArticlePreviewCard article={data.allSanityPost.edges[0].node} />
          </div>
        </div>
      </section>
      <div className="container">
        <Divider />
      </div>
      <section className="container pt-5 pb-5 mt-5 mb-5">
        <div className="row justify-space-between">
          <div className="col-xs-12 col-md-6 pb-3">
            <h1 className="caption">Resource</h1>
            <h6>The Plug has got you covered.</h6>
            <p className="subtitle1">
              Subscribe to our newsletter, The Plug, and get all the latest
              insights, strategies, and digital developments.
            </p>
          </div>
          <div className="col-xs-12 col-md-5">
            <Card canHover={false}>
              <div className="pt-2">
                <SubscribeForm />
              </div>
            </Card>
          </div>
        </div>
      </section>
      <div className="container">
        <Divider />
      </div>
    </Layout>
  )
}

export const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        description
      }
    }
    allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      filter: { slug: { current: { ne: null } } }
      limit: 2
    ) {
      edges {
        node {
          title
          excerpt
          id
          mainImage {
            asset {
              gatsbyImageData(aspectRatio: 1.78)
            }
          }
          slug {
            current
          }
          publishedAt
          author {
            name
          }
          category {
            title
            slug {
              current
            }
          }
          tags {
            title
          }
        }
      }
    }
  }
`

export default IndexPage
